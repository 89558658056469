import React, { Component } from 'react';
import {LakeFront} from './components/lakefront';

export default class App extends Component {
    render() {
        return (
            <div className="lakefront-app">
                <LakeFront />
            </div>
        );
    }
}