import React from 'react';


//Prime React Components


//Components


class Home extends React.Component {
	
	render() {
		return (	
			<div className="contactUsPage">
				<div className="grid grid-nogutter justify-content-center">
					<div className="col-8 alignCenter" >
						<h1>Contact Us</h1>
						<p style={{"fontSize":"28px"}}>
							Ron & Virgy York - <span class="mobileOnly"><a href="tel:724 927-2295">724 927-2295</a></span><span class="desktopOnly">724 927-2295</span>
						</p>
						
					</div>
					<div className="col-8 alignCenter" >
						<iframe title="1236 Nobles Ln Espyville PA 16424" className="googleMap"  id="gmap_canvas" src="https://maps.google.com/maps?q=1236%20Noble%20Ln%20Espyville%20PA&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>	
						<p style={{"fontSize":"28px"}}>
							1236 Nobles Ln Espyville, PA 16424
						</p>
					</div>
					
				</div>
			</div>
		);
	}
}

export default Home;