import React from 'react';

import Logo from '../img/logo.png';
import {Link} from 'react-router-dom'

import {Button} from 'primereact/button';

//ACTIONS

let Header = () => {

	let divButtonsLinks = <ul className="linkText">
		<li>
			<Link to="/">
				<Button className="p-button-danger p-button-sm" label="Home" ></Button>
			</Link>
		</li>
		
		<li>
			<Link to="/photoGallery">
				<Button className="p-button-danger p-button-sm" label="Photos" ></Button>
			</Link>
		</li>
		<li>
			<Link to="/contact">
				<Button className="p-button-danger p-button-sm" label="Contact" ></Button>
			</Link>
		</li>
	</ul>

	return (
		<header className="header">
			<nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light" role="navigation">
				
			   <div className="flex justify-content-between">
					<a className="navbar-brand" href="/">
						<img className="navbar-brand-logo" src={Logo} title="Pymatuning Lake House" alt="Logo"/>
					</a>
					<div className="desktopOnly">
						{divButtonsLinks}
					</div>

					<div className="flex flex-column">
						<div className="headerContactInfoPhone align-self-end">
							<strong>
								<span className="mobileOnly"><a href="tel:724 927-2295">724 927-2295</a></span>
								<span class="desktopOnly">724 927-2295</span>
							</strong>
						</div>
						
						<div className="headerContactInfoName align-self-end">
							Ron & Virgy York
						</div>
					</div>
					
				
				</div>
				<div className="mobileOnly">
						{divButtonsLinks}
					</div>
			</nav>
		  
		</header>
	)
}

export default Header;