import React from 'react';

import ImageGallery from 'react-image-gallery';

let Photos = () => {
	
	const images = [
		{
			original: '/images/front-4.jpg',
			thumbnail: '/images/front-4.jpg',
			description: '1236 Nobles Ln Espyville, PA 16424',
			originalTitle: '1236 Nobles Ln Espyville, PA 16424',
			originalAlt: '1236 Nobles Ln Espyville, PA 16424',
			thumbnailAlt: '1236 Nobles Ln Espyville, PA 16424'
		},
		{
			original: '/images/kitchen-3.jpg',
			thumbnail: '/images/kitchen-3.jpg',
			description: 'Kitchen',
			originalTitle: 'Kitchen',
			originalAlt: 'Kitchen',
			thumbnailAlt: 'Kitchen'
		},
		{
			original: '/images/kitchen-5.jpg',
			thumbnail: '/images/kitchen-5.jpg',
			description: 'Kitchen',
			originalTitle: 'Kitchen',
			originalAlt: 'Kitchen',
			thumbnailAlt: 'Kitchen'
		},
		{
			original: '/images/diningRoom-3.jpg',
			thumbnail: '/images/diningRoom-3.jpg',
			description: 'Dining Room',
			originalTitle: 'Dining Room',
			originalAlt: 'Dining Room',
			thumbnailAlt: 'Dining Room'
		},
		{
			original: '/images/diningRoom-4.jpg',
			thumbnail: '/images/diningRoom-4.jpg',
			description: 'Dining Room',
			originalTitle: 'Dining Room',
			originalAlt: 'Dining Room',
			thumbnailAlt: 'Dining Room'
		},
		{
			original: '/images/livingRoom-4.jpg',
			thumbnail: '/images/livingRoom-4.jpg',
			description: 'Living Room',
			originalTitle: 'Living Room',
			originalAlt: 'Living Room',
			thumbnailAlt: 'Living Room'
		},
		{
			original: '/images/livingRoom-5.jpg',
			thumbnail: '/images/livingRoom-5.jpg',
			description: 'Living Room',
			originalTitle: 'Living Room',
			originalAlt: 'Living Room',
			thumbnailAlt: 'Living Room'
		},
		{
			original: '/images/bedroom-6.jpg',
			thumbnail: '/images/bedroom-6.jpg',
			description: 'Bedroom One',
			originalTitle: 'Bedroom One',
			originalAlt: 'Bedroom One',
			thumbnailAlt: 'Bedroom One'
		},
		{
			original: '/images/bedroom-7.jpg',
			thumbnail: '/images/bedroom-7.jpg',
			description: 'Bedroom Two',
			originalTitle: 'Bedroom Two',
			originalAlt: 'Bedroom Two',
			thumbnailAlt: 'Bedroom Two'
		},
		{
			original: '/images/bedroom-8.jpg',
			thumbnail: '/images/bedroom-8.jpg',
			description: 'Bedroom Three',
			originalTitle: 'Bedroom Three',
			originalAlt: 'Bedroom Three',
			thumbnailAlt: 'Bedroom Three'
		},
		{
			original: '/images/sunroom-1.jpg',
			thumbnail: '/images/sunroom-1.jpg',
			description: 'Sunroom',
			originalTitle: 'Sunroom',
			originalAlt: 'Sunroom',
			thumbnailAlt: 'Sunroom'
		},
		{
			original: '/images/bathroom-3.jpg',
			thumbnail: '/images/bathroom-3.jpg',
			description: 'Bathroom',
			originalTitle: 'Bathroom',
			originalAlt: 'Bathroom',
			thumbnailAlt: 'Bathroom'
		},
		{
			original: '/images/back-2.jpg',
			thumbnail: '/images/back-2.jpg',
			description: '1236 Nobles Ln Espyville, PA 16424',
			originalTitle: '1236 Nobles Ln Espyville, PA 16424',
			originalAlt: '1236 Nobles Ln Espyville, PA 16424',
			thumbnailAlt: '1236 Nobles Ln Espyville, PA 16424'
		},
		{
			original: '/images/drone-1.png',
			thumbnail: '/images/drone-1.png',
			description: 'Pymatuning Lake',
			originalTitle: 'Pymatuning Lake',
			originalAlt: 'Pymatuning Lake',
			thumbnailAlt: 'Pymatuning Lake'
		},
		{
			original: '/images/drone-2.png',
			thumbnail: '/images/drone-2.png',
			description: 'Espyville Pennsylvania',
			originalTitle: 'Espyville Pennsylvania',
			originalAlt: 'Espyville Pennsylvania',
			thumbnailAlt: 'Espyville Pennsylvania'
		},
		{
			original: '/images/drone-3.png',
			thumbnail: '/images/drone-3.png',
			description: '1236 Nobles Ln Espyville, PA 16424',
			originalTitle: '1236 Nobles Ln Espyville, PA 16424',
			originalAlt: '1236 Nobles Ln Espyville, PA 16424',
			thumbnailAlt: '1236 Nobles Ln Espyville, PA 16424'
		},
		
	]
	
	return (
		<div className="photosPage" >
			<div>
				<ImageGallery items={images} showPlayButton={false} thumbnailPosition="top"/>
			</div>
		</div>
	)
}



export default Photos;