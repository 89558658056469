import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Header from './header';
import Footer from './footer';

//PAGES
import Home from './home';
import PhotoGallery from './photoGallery';
import Contact from './contact';

//ACTIONS

export class LakeFront extends React.Component {	
	render() {
		return (
			
			<div className="container-fluid">
				<Header />
				<main className="main">
					<Routes>
						
						<Route path="/photoGallery" element={<PhotoGallery />} />
						<Route path="/contact" element={<Contact />}/>
						<Route path="/" element={<Home />} />
					</Routes>
				</main>
				<Footer />
			</div>
			
		)
	}
}