import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="footer-legal">Pymatuning Lake House {new Date().getFullYear()}</div>
                <div className="footer-credit">
                    Developed by B.Y.
                </div>
            </footer>
        )
    }
}

export default Footer;