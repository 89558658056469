import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import {HelmetProvider} from "react-helmet-async";
import './style/index.scss';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <HashRouter>
			<HelmetProvider>
           		<App />
			</HelmetProvider>	
        </HashRouter>
    </React.StrictMode>
);
