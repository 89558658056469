import React from 'react';
import {Link} from 'react-router-dom'

import Front from '../img/front-4-cropped.jpg';

class Home extends React.Component {
	render() {
		
		
		return (	
			<div className="homePage">
				<div className="grid grid-nogutter justify-content-center">
						<div className="col-12 md:col-10 lg:col-8 card" >
							<h1>Escape to The Lake House of Pymatuning</h1>
							<p>
								This 3 bedroom rental cottage in Espyville, Pennsylvania is the ideal spot for anglers looking to escape to the great outdoors. 
								The cottage features three bedrooms with comfortable beds, a fully-equipped kitchen, dining room, and a living room with a flat TV. 
								The cottage also has a large fire pit with outdoor seating, perfect for enjoying the beautiful natural surroundings. 
								The cottage is located 500 feet from Pymatuning lake, which has a variety of fish, including walleye, crappie, and perch. 
							</p>
							<p>	
								There is easy access to:
								<ul>
									<li>
										<a href="https://www.facebook.com/yorkiegrilleandcustard/" target="_blank" rel="noopener noreferrer">Yorkie's Grille & Custard</a>
									</li>
									<li>
										<a href="https://www.espyvilleboatrentals.com/" target="_blank" rel="noopener noreferrer">Espyville Marina</a>
									</li>
									<li>
										<a href="https://www.eatatrussells.com/" target="_blank" rel="noopener noreferrer">Russells BBQ</a>
									</li>
									<li>
										<a href="https://www.facebook.com/profile.php?id=100083385515027" target="_blank" rel="noopener noreferrer">Lil' Bit Restaurant</a>
									</li>
									<li>
										<a href="https://www.facebook.com/PoffsPlace/" target="_blank" rel="noopener noreferrer">Poff's Place</a>
									</li>
									<li>
										<a href="https://www.facebook.com/Country-Side-Family-Restaurant-314196098686051/" target="_blank" rel="noopener noreferrer">Country Side Family Restaurant</a>
									</li>
								</ul> 
							</p>
							
							<p>
								 Please call Ron or Virgy York at <strong><span className="mobileOnly"><a href="tel:724 927-2295">724 927-2295</a></span><span class="desktopOnly">724 927-2295</span></strong> for more information.  If there isn't any answer, please leave a message and we will 
								 call you right back.  The rate is $75 per night or $500 per week. Also added to the rate is a 6% state sales tax and a 5% county bed tax. There will also be 
								 a $100.00 security deposit. We will need half of the rent and half of the security deposit before you arrive.  
							</p>
						</div>
						<div className="col-12 md:col-10 lg:col-8" >
							<div className="grid grid-nogutter justify-content-center">
								<div className="col-12  home-page-image-small alignCenter" >
									<Link to="/photoGallery">
										<img className="home-page-image-front" onClick={(e) => this.setState({visible:true})} src={Front} title="Pymatuning Lake House" alt="Pymatuning Lake House"/>
									</Link>
								</div>
								
								
							</div>
						
						</div>
					</div>
			</div>
		);
	}
}

export default Home;